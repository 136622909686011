<template>
  <div class="ticket-empty" :class="support ? '' : 'ticket-empty--user'"></div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "TicketEmpty",
  computed: {
    ...mapState({
      support: state => state.oauth.support
    })
  }
};
</script>

<style lang="scss" scoped>
.ticket-empty {
  height: 100%;
  background-image: url("../assets/images/empty-ticket-support.svg");
  background-repeat: no-repeat;
  background-position: center;

  &--user {
    background-image: url("../assets/images/empty-ticket-user.svg");
  }
}
</style>
